import * as React from "react";
import { createRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import * as M from "materialize-css";
import axios from "axios";
import {Fader} from "../fader/Fader";
import {HeaderSlide} from "./HeaderSlide";
import { useLocale } from "../../hooks/useLocale"; import { backendServerUrl } from "../../globals";

interface Slide {
	image: any;
	imageMobile?: any;
	text: any;
	links: any[];
	[key: string]: any;
}

interface SlideLinkDto {
	id:number;
	locale: string;
	text: string
	href: string;

}

interface SlideDto {
	id:number;
	title: string;
	locale: string;
	textUrl: string;
	backgroundUrl: string;
	backgroundMobileUrl: string;
	links: SlideLinkDto[];
}

export const Header = () => {
	const lang = useLocale();
	const [slidesArray, setSlidesArray] = useState<Slide[]>([]);

	const [carouselItems, setCarouselItems] = useState<HTMLElement[]>([]);
	const carousel = createRef<HTMLDivElement>();
	let carouselInstance = useRef<M.Carousel>();

	let interval = useRef<NodeJS.Timeout>();

	const slideChangeInterval = 6000;
	const changeSlide = () => carouselInstance.current!.next();
	const initializeTimer = () => interval.current = setInterval(changeSlide, slideChangeInterval);

	useEffect(() => {
		const slides: any[] = slidesArray.map((slide, i) => {
			return <HeaderSlide key={i} backImg={slide.image} mobileImg={slide.imageMobile} textImg={slide.text} links={[slide.links]}/>
		});
		setCarouselItems(slides);
	}, [slidesArray]);

	useEffect(() => {
		if (carousel.current && carouselItems.length > 0) {
			carouselInstance.current = M.Carousel.init(carousel.current, {
				numVisible: 1,
				indicators: true,
				noWrap: false,
				fullWidth: true,
			});
		}

		// initializeTimer();
		// eslint-disable-next-line
	}, [carouselItems]);

	useEffect(() => {
		axios.get(backendServerUrl + "/slides?lang=" + lang)
			.then(res => {
				setSlidesArray((res.data as SlideDto[]).map(dto => {
					return {
						image: backendServerUrl + dto.backgroundUrl,
						imageMobile: dto.backgroundMobileUrl ? backendServerUrl + dto.backgroundMobileUrl : undefined,
						text: backendServerUrl + dto.textUrl,
						links: dto.links.map(link => {
							if (link.href.startsWith("http")) {
								return <a target="_blank" rel="noopener noreferrer" href={link.href}>{link.text}</a>;
							} else if (link.href.startsWith("/api")) {
								return <a href={backendServerUrl + link.href.substring("/api".length)}>{link.text}</a>;
							} else {
								return <Link to={link.href}>{link.text}</Link>;
							}
						})
					}
				}))
			})
	}, [lang])

	return (
		<header>
			<button className="carousel-prev"
			        onClick={() => [carouselInstance.current!.prev(), clearInterval(interval.current!), initializeTimer()]}>
				<i className="material-icons">chevron_left</i>
			</button>
			<div ref={carousel} className="carousel carousel-slider">
				{carouselItems.map((item) => item)}
			</div>
			<button className="carousel-next"
			        onClick={() => [carouselInstance.current!.next(), clearInterval(interval.current!), initializeTimer()]}>
				<i className="material-icons">chevron_right</i>
			</button>
			<Fader/>
		</header>
	);
};
