import React from "react";
import { Notice } from "../Notice";
import { AboutEducator } from "../AboutEducator";
import langs from "../../../localization/languages/languages";
import { useLocale } from "../../../hooks/useLocale";
import "../CoursesPage.css";
import lvl2Img from "../../../assets/img/courseLevels/nivo2.jpg";
import { CourseCard } from "../CourseCard";
import { Redirecter } from "../../../components/redirecter/Redirecter";
import routes from "../../../localization/routes/routes";
import { Link } from "react-router-dom";

export const Level2Course = () => {
	const lang = useLocale();

	const lvl2Card = {
		id: langs[lang].course_lvl_2_card_title.replace(" ", "-").toLowerCase(),
		description: langs[lang].course_lvl_2_card_text,
		title: langs[lang].course_lvl_2_card_title,
		avatar: lvl2Img,
		date: langs[lang].course_lvl_2_card_date,
		btnText: langs[lang].course_lvl_2_card_btn,
	};

	if (lang === "en")
		return <Redirecter to={routes[lang].home}/>;

	return (
		<div className="course-description">
			<div className="container">
				<CourseCard {...lvl2Card}/>
				<div className="row">
					<div className="col s12">
						<h3>
							NIVO 2: Uvod i tehnika terapeutske masaže
						</h3>
						<div className="col s12">
							<p>
								<span className="bold-text">Datum održavanja kursa:</span> 1.7 - 23.7.2023.<br/>
								{/*<span className="bold-text">Datum početka kursa:</span> 29.04.2023.<br/>*/}
							</p>

							<h4>Raspored predavanja:</h4>
							<p>Subotom i nedeljom 10-15.30h</p>

							<h4>Šta je terapeutska masaža?</h4>
							<p>Terapeutska masaža je adaptirana tehnika kojom se identifikuju specifične potrebe svakog
								pojedinca. Osnovni cilj je otklanjanje mišićne napetosti uslovljene lošom posturom ili
								intenzivnim naporom. Manipulacija mekog tkiva koja doprinosi oporavku mišića u značajnoj
								meri.</p>

							<h4>Kome je namenjen ovaj kurs?</h4>
							<p>Za sve one koji žele da svoje poznavanje masaže unaprede ili da se ovom praksom bave
								profesionalno.</p>

							<h4>Cilj kursa:</h4>
							<p>Savladavanje terapeutskih masažnih tehnika i bezbedno izvođenje istih.</p>

							<h4>Zašto izabrati našu školu?</h4>
							<ul>
								<li>✓ Zapošljavamo najbolje polaznike</li>
								<li>✓ Većina naših polaznika se bavi profesionalnom masažom nakon kursa ili ima svoj
									biznis
								</li>
								<li>✓ Naučićemo Vas masaži na način koji će učiniti da se osećate samopouzdano i sigurno
									za rad
								</li>
								<li>✓ Praktični deo kursa u se izvodi na realnim klijentima, uz nadzor edukatora koji će
									vam pomoći da dosegnete svoj maksimum u učenju i vežbanju novih tehnika
								</li>
								<li>✓ Omogućavamo Vam 70% praktičnog učenja prilikom kojeg se teorijski deo utvrđuje
								</li>
								<li>✓ Predavanja se održavaju u savremenoj i klimatizovanoj učinioci</li>
								<li>✓ Koristimo modernu opremu u edukaciji polaznika</li>
								<li>✓ Imamo višegodišnje iskustvo u edukaciji masaže</li>
								<li>✓ Učenje je u malim grupama</li>
								<li>✓ Potpuno se povećujemo svakom polazniku</li>
							</ul>

							<h4>Polaganje:</h4>
							<p>Na kraju svih predavanja, polaže se teorijski i praktični test. Za prolaznost, neophodno
								je demonstrirati minimum 80% savladanog gradiva.</p>

							<h4>Datumi polaganja:</h4>
							<ul>
								<li>Polaganje 29.07.</li>
								{/*<li>Ponovno polaganje 9.12.</li>*/}
							</ul>
							<p>Na kraju svih predavanja, polaže se teorijski i praktični test. Za prolaznost, neophodno
								je demonstrirati minimum 80% savladanog gradiva.</p>

							<h4>Cena kursa:</h4>
							<p>
								35.000 RSD
							</p>
							<p>Za uplaćena oba nivoa obuke (NIVO 1 i NIVO 2), ostvarujete popust na ukupan iznos od 5000
								dinara.</p>

							<h4>Šta je uključeno u cenu?</h4>
							<ul>
								<li>✓ Predavanja</li>
								<li>✓ Praksa</li>
								<li>✓ Materijal za praktično učenje masaže</li>
								<li>✓ Literatura</li>
								<li>✓ Uverenje o položenom kursu izdato od strane Centra za masažu i edukaciju Zen</li>
							</ul>

							<h4>Šta nije uključeno u cenu?</h4>
							<ul>
								<li>✓ Sertifikat o položenom kursu od strane narodnog Univerziteta u Nišu (opciono).
								</li>
								<li>✓ Dodatno polaganje ispita (2000 dinara po testu)</li>
							</ul>

							<h4>Kako se prijaviti za kurs?</h4>
                            <p>
                                Prijavu za kurs možete izvršiti na adresi
                                Milorada Veljkovića Špaje broj 14 (na prvom
                                spratu) svakog radnog dana od 10-22h, subotom
                                10-20h i nedeljom 12-20h. Za prijavu je
                                neophodno priložiti očitanu ličnu kartu i
                                depozit u iznosu od 10.000 dinara. Depozit
                                postaje nepovratan ukoliko od kursa odustanete u
                                bilo kom trenutku kraćem od 2 nedelje pre nego
                                što kurs počne.
                            </p>


							<h4>Napomene:</h4>
							<ul>
								<li>✓ Propuštene časove ne nadoknađujemo.</li>
								<li>✓ Da bi se upisao ovaj nivo kursa, neophodan je uspešno završeni Nivo 1.</li>
							</ul>

							<h4>Edukatori:</h4>

							{langs[lang].course_educators.map(educator => <AboutEducator {...educator} />)}

							<p>
								Youtube:&nbsp;
								<a className="gold" target="_blank" rel="noreferrer noopener"
								   href="https://www.youtube.com/channel/UCR3ukSUO3b5fQFnjHn_8oiQ">
									https://www.youtube.com/channel/UCR3ukSUO3b5fQFnjHn_8oiQ
								</a>
							</p>

							<Notice text={
								<p>Za sva dodatna pitanja u vezi kursa, pišite nam na <a className="gold"
								                                                         href="mailto:office@zenmasaza.com">office@zenmasaza.com</a>
								</p>
							}/>
							<p>
								<Link to={"/rs/" + routes["rs"].courses_level_weekend_seminar}
								      className="btn bg-dark-gold text-white btn-gold">
									Vikend Seminari
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
