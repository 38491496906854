import * as React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { ContactListItem } from "../../pages/contact/ContactListItem";
import langs from "../../localization/languages/languages";
import { localizeLinkTo } from "../../util/localization";
import routes from "../../localization/routes/routes";
import * as contactJSON from "../../static/contact.json";
import { useLocale } from "../../hooks/useLocale";

const fbUrl = "https://www.facebook.com/masaza.nis2/?ref=br_rs";
const igUrl = "https://www.instagram.com/Centar_za_masazu_Zen/";
const ytUrl = "https://www.youtube.com/channel/UCR3ukSUO3b5fQFnjHn_8oiQ";

export const Footer = () => {
const lang = useLocale();

	return (
		<footer>
			<div className="row">
				<div className="col s12 m12 l1"/>
				<div className="col s12 m12 l10">
					<div className="row">
						<div className="row-col col s12 m12 l4">
							<ContactListItem
								title={langs[lang].contact_list_item_hours_open}
								caption={"10:00/22:00"}
							/>
							<ContactListItem
								title={langs[lang].contact_list_item_saturday}
								caption={"10:00/20:00"}
							/>
							<ContactListItem
								title={langs[lang].contact_list_item_sunday}
								caption={"12:00/20:00"}
							/>
						</div>
						<div className="row-col col s12 m12 l4">
							<h6>{langs[lang].footer_location_1_text}</h6>
							<h6><a className="gold" href={contactJSON.mapURL_1}>{langs[lang].footer_address_text_1}</a></h6>
							<h6>{langs[lang].footer_location_2_text}</h6>
							<h6><a className="gold" href={contactJSON.mapURL_2}>{langs[lang].footer_address_text_2}</a></h6>
							<h6><a className="gold"  href="mailto://info@zenmasaza.com">INFO@ZENMASAZA.COM</a></h6>
							<h6><a href={"tel:+381-64-04-88-256"} className="gold">+381 (0) 64 04 88 256</a></h6>
						</div>
						<div className="row-col col s12 m12 l4">
							<button className="btn-gold bg-dark-gold"><Link to={localizeLinkTo(`/${routes[lang].reservation}`, lang)}>{langs[lang].footer_reservation_button}</Link></button>
							<br/>
							<button className="btn-gold bg-dark-gold"><Link to={localizeLinkTo(`/${routes[lang].career}`, lang)} target="_blank">{langs[lang].footer_career_button}</Link></button>
							<br/>
							<button className="btn-gold bg-dark-gold"><Link to={localizeLinkTo(`/${routes[lang].faq}`, lang)}>{langs[lang].footer_faq_text}</Link>
							</button>
							<br/>
						</div>
					</div>

				</div>
			</div>
			<div className="row social-icon-container container">
				<div className="col s12 m12 l3"/>
				<div className="col s4 m4 l2">
					{/*eslint-disable-next-line*/}
					<a target={"blank"} href={igUrl} className="footer-icon ig"/>
				</div>
				<div className="col s4 m4 l2">
					{/*eslint-disable-next-line*/}
					<a target={"blank"} href={fbUrl} className="footer-icon fb"/>
				</div>
				<div className="col s4 m4 l2">
					{/*eslint-disable-next-line*/}
					<a target={"blank"} href={ytUrl} className="footer-icon yt"/>
				</div>
				<div className="col s12 m12 l3"/>
			</div>
			<div className="footer-bottom-bar">
				<div className="container">
					© 2023 Centar za masažu Zen. All rights reserved. This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
					{/*{langs[lang].footer_developed}<a target="blank" rel="noreferrer noopener" href="https://www.linkedin.com/in/7aske/">Taske</a>, <a rel="noreferrer noopener" target="blank" href="https://www.linkedin.com/in/nikola-cvetkovic-216327169">Bangie</a>, <a rel="noreferrer noopener" target="blank" href="https://www.linkedin.com/in/petar-kocic-74a987190">Kole</a> &amp; <a rel="noreferrer noopener" target="blank" href="https://www.instagram.com/_oposum_">Dika</a>*/}
				</div>
			</div>
		</footer>
	);
};
