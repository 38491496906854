import * as React from "react";

type HeaderSlideProps = {
	backImg: any;
	mobileImg?: any;
	textImg?: any;
	links?: any[];
};
export const HeaderSlide = (props: HeaderSlideProps) => {
	return (
		<div>
			<div key={Math.floor(Math.random() * 1000)} className="carousel-item">
				<img className="background" src={props.backImg} alt="Slide"/>
				{ props.mobileImg ?
				<img className="mobile-background" src={props.mobileImg} alt="Slide"/> : undefined }
				<div className="container">
					{props.textImg ? <img className="overlay" src={props.textImg} alt="Slide text"/> : ""}
					<div className='slide-links'>
					{props.links ? props.links.map(link => link) : ""}
					</div>
				</div>
			</div>
		</div>
	);
};
