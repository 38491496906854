import React from "react";
import "./assets/css/helpers.css";
import "./assets/css/common.css";
import "./assets/css/scrollbar.css";
import "materialize-css/dist/css/materialize.css";
import { HashRouter } from "react-router-dom";
import { NavigationBar } from "./components/navbar/NavigationBar";
import { Footer } from "./components/footer/Footer";
import { LocaleFixRedirecter } from "./components/redirecter/LocaleFixRedirecter";
import Router from "./router/Router";

const App = () => {
	return (
		<main>
			<div className="App">
				<HashRouter basename="/">
					<NavigationBar/>
					<LocaleFixRedirecter/>
					<Router/>
					<Footer/>
				</HashRouter>
			</div>
		</main>
	);
};

export default App;
