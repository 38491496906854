import React from "react";
import { Notice } from "../Notice";
import { AboutEducator } from "../AboutEducator";
import langs from "../../../localization/languages/languages";
import { Link } from "react-router-dom";
import { useLocale } from "../../../hooks/useLocale";
import "../CoursesPage.css";
import { CourseCard } from "../CourseCard";
import lvl1Img from "../../../assets/img/courseLevels/nivo1.jpg";
import { Redirecter } from "../../../components/redirecter/Redirecter";
import routes from "../../../localization/routes/routes";


export const Level1Course = () => {
	const lang = useLocale();

	const lvl1Card = {
		id: langs[lang].course_lvl_1_card_title.replace(" ", "-").toLowerCase(),
		description: langs[lang].course_lvl_1_card_text,
		title: langs[lang].course_lvl_1_card_title,
		avatar: lvl1Img,
		date: langs[lang].course_lvl_1_card_date,
		btnText: langs[lang].course_lvl_1_card_btn,
	};

	if (lang === "en")
		return <Redirecter to={routes[lang].home}/>;

	return (
		<div className="course-description">
			<div className="container">
				<CourseCard {...lvl1Card}/>
				<div className="row">
					<div className="col s12">
						<h3>
							NIVO 1 - Uvod i osnovna tehnika relaksacione masaže
						</h3>
						<div className="col s12">
							<p>
								{/*<span className="bold-text">Datum održavanja kursa:</span> 3.10 - 31.10.2022.<br/>*/}
								<span className="bold-text">Datum održavanja kursa:</span> 27.5-18.6.2023.<br/>
								<span className="bold-text">Raspored predavanja:</span> Subotom i nedeljom od
								10 do 15.30h <br/>
							</p>

							<h4>Šta je relaksaciona masaža?</h4>
							<p>Relaksaciona masaža je opuštajuća tehnika koja pre svega ima za cilj opuštanje organizma.
								Pokreti su umirujući i pomažu osobi koja prima masažu da povrati unutrašnji osećaj
								balansa, kao i da otkloni simptome umora i mišićne napetosti.</p>

							<h4>Kome je namenjen ovaj kurs?</h4>
							<p>Svima koji žele da se bave masažom iz hobija ili onima kojima je potrebno osnovno znanje
								za započinjanje karijere kao terapeut masaže.</p>

							<h4>Cilj kursa:</h4>
							<p>Savladavanje relaksacione masaže celog tela, uz poznavanje anatomije i telesnih sistema.
								Učenje o indikacijama i kontraindikacijama za izvođenje tretmana. Učenje svih osnovnih
								elemenata koje masažni tretman obuhvata (učenje o opremi, raznim vrstama masaže koje
								postoje, profesionalno ophođenje u praksi).</p>

							<h4>Zašto izabrati našu školu?</h4>
							<p>
								<ul>
									<li>✓ Zapošljavamo najbolje polaznike!</li>
									<li>✓ Većina naših polaznika se bavi profesionalnom masažom nakon kursa ili ima svoj
										biznis
									</li>
									<li>✓ Naučićemo Vas masaži na način koji će učiniti da se osećate samopouzdano i
										sigurno za rad
									</li>
									<li>✓ Praktični deo kursa u se izvodi na realnim klijentima, uz nadzor edukatora
										koji će vam pomoći da dosegnete svoj maksimum u učenju i vežbanju novih tehnika
									</li>
									<li>✓ Omogućavamo Vam 70% praktičnog učenja prilikom kojeg se teorijski deo
										utvrđuje
									</li>
									<li>✓ Predavanja se održavaju u savremenoj i klimatizovanoj učinioci</li>
									<li>✓ Koristimo modernu opremu u edukaciji polaznika</li>
									<li>✓ Imamo višegodišnje iskustvo u edukaciji masaže</li>
									<li>✓ Učenje je u malim grupama</li>
									<li>✓ Potpuno se povećujemo svakom polazniku</li>
								</ul>
							</p>

							<h4>Polaganje</h4>
							<p>Na kraju svih predavanja, polaže se teorijski i praktični test. Za prolaznost, neophodno
								je demonstrirati minimum 80% savladanog gradiva.</p>

							{/*<h4>Datumi polaganja</h4>*/}
							{/*<p>*/}
							{/*	<ul>*/}
							{/*		<li>Polaganje 17.04.</li>*/}
									{/*<li>Ponovno polaganje 4.11.</li>*/}
								{/*</ul>*/}
							{/*</p>*/}

							<h4>Cena kursa:</h4>
							<p>35.000 RSD</p>

							<h4>Šta je uključeno u cenu?</h4>
							<p>
								<ul>
									<li>✓ Predavanja</li>
									<li>✓ Praksa</li>
									<li>✓ Materijal za praktično učenje masaže</li>
									<li>✓ Literatura</li>
									<li>✓ Uverenje o položenom kursu izdato od strane Centra za masažu i edukaciju Zen
									</li>
								</ul>
							</p>

							<h4>Šta nije uključeno u cenu?</h4>
							<p>
								<ul>
									<li>✓ Sertifikat o položenom kursu od strane narodnog Univerziteta u Nišu
										(opciono).
									</li>
									<li>✓ Dodatno polaganje ispita (3000 dinara po testu)</li>
								</ul>
							</p>

							<h4>Kako se prijaviti za kurs?</h4>
							<p>
								Prijavu za kurs možete izvršiti na adresi
								Milorada Veljkovića Špaje broj 14 (na prvom
								spratu) svakog radnog dana od 10-22h, subotom
								10-20h i nedeljom 12-20h. Za prijavu je
								neophodno priložiti očitanu ličnu kartu i
								depozit u iznosu od 10.000 dinara. Depozit
								postaje nepovratan ukoliko od kursa odustanete u
								bilo kom trenutku kraćem od 2 nedelje pre nego
								što kurs počne.
							</p>

							<h4>Napomena:</h4>
							<p>Propuštene časove ne nadoknađujemo.</p>

							<h4>Edukatori:</h4>

							{langs[lang].course_educators.map(educator => <AboutEducator {...educator} />)}

							<p>
								Youtube:&nbsp;
								<a className="gold" target="_blank" rel="noreferrer noopener"
								   href="https://www.youtube.com/channel/UCR3ukSUO3b5fQFnjHn_8oiQ">
									https://www.youtube.com/channel/UCR3ukSUO3b5fQFnjHn_8oiQ
								</a>
							</p>

							<Notice text={
								<p>
									Za sva dodatna pitanja u vezi kursa,
									pišite nam na <a className="gold"
									                 href="mailto:office@zenmasaza.com">office@zenmasaza.com</a>
								</p>
							}/>
							<p>
								<Link to={"/rs/" + routes["rs"].courses_level_weekend_seminar}
								      className="btn bg-dark-gold text-white btn-gold">
									Vikend Seminari
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

